<template>
    <ODataLookup :data-object="dsPackagesLookup" :whereClause="`DescendantOrgUnit_ID = ${OrgUnitId}`" reloadOnWhereClauseChange>
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue" readonly>
            <span v-else :ref="target" style="cursor:pointer;">    
                <slot name="slotArea"></slot>            
            </span>
        </template>
        <OColumn field="ID" width="80"></OColumn>
        <OColumn field="Name" width="150"></OColumn>
        <OColumn field="Title" width="300"></OColumn> 
        <OColumn field="OrgUnit" width="200"></OColumn>
    </ODataLookup>
</template>

<script setup>
    import { defineProps } from 'vue';
    import { ODataLookup } from 'o365-datalookup';

    const props = defineProps({
        is: String,
        OrgUnitId: Number,    
        textInputValue: String,
        textInput: Boolean
    });

    const dsPackagesLookup = $getDataObjectById("dsScope_PackagesLookup");

</script>